import { Component, EventEmitter, Input, Output, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogService } from 'src/app/services/dialog-service/dialog.service';
import { TasksApiService } from 'src/app/services/api/tasks/tasks-api-service.service';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-task-bulk-update',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './task-bulk-update.component.html',
  styleUrls: ['./task-bulk-update.component.css']
})
export class TaskBulkUpdateComponent {
  @Input() selectedTickets: SelectionModel<any>;
  @Input() ticketsData: any[];
  @Output() refreshData = new EventEmitter<void>();

  public isLoading = false; 

  constructor(
    private dialogService: DialogService,
    private viewReference: ViewContainerRef,
    private tasksApiService: TasksApiService
  ) {}

  completeSelected() {
    if (!this.selectedTickets.selected.length) {
      return;
    }

    this.isLoading = true;

    const completionPromises = this.selectedTickets.selected.map(taskId =>
      this.tasksApiService.markComplete(taskId).catch(err => {
        return null;
      })
    );

    Promise.all(completionPromises)
      .then(results => {
        const successfulCompletions = results.filter(result => result !== null).length;
        console.log(`${successfulCompletions} task(s) marked as completed successfully.`);
        this.selectedTickets.clear();
        this.refreshData.emit();
      })
      .catch(err => console.error('Error completing tasks:', err))
      .finally(() => {
        this.isLoading = false;
      });
  }

  deleteSelected() {
    if (!this.selectedTickets.selected.length) {
      return;
    }

    this.dialogService
      .createDialog(
        ModalConfirmationComponent,
        'Are you sure you want to delete the selected tasks?',
        this.viewReference
      )
      .then(confirmed => {
        if (confirmed) {
          this.isLoading = true;

          const deletionPromises = this.selectedTickets.selected.map(taskId =>
            this.tasksApiService.delete(taskId).catch(err => {
              return null;
            })
          );

          Promise.all(deletionPromises)
            .then(results => {
              const successfulDeletions = results.filter(result => result !== null).length;
              console.log(`${successfulDeletions} task(s) deleted successfully.`);
              this.selectedTickets.clear();
              this.refreshData.emit();
            })
            .catch(err => console.error('Error deleting tasks:', err))
            .finally(() => {
              this.isLoading = false;
            });
        }
      })
      .catch(() => {});
  }
}
