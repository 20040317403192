
<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading" class="container-fluid p-l-20 p-r-20">
  
  <div class="table-responsive">
 
    <table class="table table-borderless" style="table-layout: auto; width: auto;">
      <tbody>
  <!-- Trigger Delay Section -->
        <tr>
          <td>
            <label for="TriggerDelay" class="formHeader mb-0 me-3">Trigger Delay (Hrs) <span class="text-danger">*</span>
              <span>
                <i [matTooltip]="'Use this trigger delay to customize when the survey is received. Example, having 48 hrs delay means the survey will be sent 48 hrs after the ticket has been resolved.'"
                [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
                class="far fa-question-circle m-l-5"></i>
              </span>
            </label>
          </td>
          <td>
            
            <input [(ngModel)]="triggerDelay" 
            type="number" 
            class="form-control form-control-sm" 
            id="TriggerDelay" 
            placeholder="Enter Trigger Delay" 
            autocomplete="off" 
            min="0"
            style="width: 200px;">

          </td>
          <td>

            <button (click)="updateTriggerDelay()" 
            class="btn btn-primary btn-sm" 
            [disabled]="isSaving">Save</button>

          </td>
        </tr>

  <!-- Survey reminedSection -->
        <tr>
          <td>
            <label for="SurveyReminder" class="formHeader mb-0 me-3">Survey Reminder (Days) <span class="text-danger">*</span>
              <span>
                <i [matTooltip]="'Adjust the survey reminder interval in days. For example, setting it to 30 means the survey will be sent every 30 days for incomplete surveys. If the survey reminder is set to 0, it means the reminder is disabled.'"
                [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
                class="far fa-question-circle m-l-5"></i>
              </span>
             </label>     
          </td>
          <td>

            <input [(ngModel)]="surveyreminder" 
            type="number" 
            class="form-control form-control-sm" 
            id="SurveyReminder" 
            placeholder="Enter Survey Reminder" 
            autocomplete="off" 
            min="1"
            style="width: 200px;">
     
          </td>
          <td>

            <button (click)="updateSurveyReminder()" 
            class="btn btn-primary btn-sm" [disabled]="isSavingReminder"
            >Save</button>

          </td>
        </tr>

  <!-- Random Survey Ratio Section -->
        <tr>
          <td>

            <label for="SurveyRatio" class="formHeader mb-0 me-3">Random Survey Ratio (Out of 10) <span class="text-danger">*</span>
              <span>
                <i [matTooltip]="'Set the survey distribution ratio. Example: Selecting 3 means 3 out of 10 resolved tickets will receive a survey.'"
                  [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
                  class="far fa-question-circle m-l-5"></i>
              </span>
            </label>
      
          </td>
          <td>

            <select [(ngModel)]="surveyRatio" class="form-control form-control-sm" id="SurveyRatio" style="width: 200px;">
              <option *ngFor="let num of  ratioRange" [value]="num">{{ num }}</option>
            </select>
      
          </td>
          <td>
            
            <button (click)="updateSurveyRatio()" class="btn btn-primary btn-sm" [disabled]="isSavingRatio">Save</button>
          
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <!-- Custom Survey List Section -->
  <div class="row">
    <div class="col-12">
      <table class="table align-middle tableStyle">
        <thead>
          <tr>
            <th class="col-sm-12">Custom Survey
              <button (click)="create()" class="btn btn-primary float-end">Create</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of data">
            <td>
              <span class="divLink" (click)="edit(row.Id)">{{ row.FieldName }}</span>
              <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(row.Id)"></i>
              <i class="fas fa-cog float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
