<div *ngIf="isLoading || isLoadingOptions || isLoadingGlobalSettings">
  <app-loading-spinner></app-loading-spinner>
</div>

<div class="row m-t-20" *ngIf="!isLoading && !isLoadingOptions && !isLoadingGlobalSettings">
  <div class="col-12">

    <ul class="nav m-b-5">
      <li class="nav-item divLink" [routerLink]="['../settings']" *ngIf="!isCmdbEnabled()">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Settings
        </a>
      </li>

      <li class="nav-item divLink" [routerLink]="['../customfields']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Custom Fields
        </a>
      </li>

      <li class="nav-item divLink" [routerLink]="['../status']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Status
        </a>
      </li>

      <li class="nav-item divLink" [routerLink]="['../assetTypes']" *ngIf="isCmdbEnabled()">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Asset Types
        </a>
      </li>

      <li *ngIf="values.ImpactEnabled" class="nav-item divLink" [routerLink]="['../impact']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Impact
        </a>
      </li>

      <li *ngIf="values.UrgencyEnabled" class="nav-item divLink" [routerLink]="['../urgency']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Urgency
        </a>
      </li>

      <li *ngIf="values.PriorityEnabled" class="nav-item divLink" [routerLink]="['../priority']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Priority
        </a>
      </li>

      <li *ngIf="values.AutoPriority" class="nav-item divLink" [routerLink]="['../autopriority']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Auto Priority
        </a>
      </li>

      <li *ngIf="values.ResolutionCodesEnabled" class="nav-item divLink" [routerLink]="['../resolutioncodes']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Resolution Codes
        </a>
      </li>

      <li *ngIf="values.ChangeManagementEnabled" class="nav-item divLink" [routerLink]="['../cabs']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Change Advisory Boards
        </a>
      </li>

      <li *ngIf="values.MIModuleEnabled" class="nav-item divLink" [routerLink]="['../mitasks']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Major Incident Tasks
        </a>
      </li>

      <li *ngIf="values.SecurityOperationsCentreEnabled" class="nav-item divLink" [routerLink]="['../soc-feeds']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          SOC Direct Feeds
        </a>
      </li>

      <li *ngIf="values.SecurityOperationsCentreEnabled" class="nav-item divLink" [routerLink]="['../soc-email-feeds']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          SOC Email Feeds
        </a>
      </li>

      <li *ngIf="values.SecurityOperationsCentreEnabled" class="nav-item divLink"
          [routerLink]="['../soc-webhook-feeds']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          SOC Webhook Feeds
        </a>
      </li>

      <li *ngIf="values.SecurityOperationsCentreEnabled" class="nav-item divLink"
          [routerLink]="['../soc-scheduled-tasks']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          SOC Scheduled Tasks
        </a>
      </li>

      <li *ngIf="values.SurveyEnabled" class="nav-item divLink"
      [routerLink]="['../custom-survey']">
    <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      Custom Survey                                                                                                                                                                                                                                                                                                  
    </a>
  </li>

    </ul>
  </div>
  <div class="col-12 sectionBox">
    <div class="m-t-20">

      <div *ngIf="selectedTab == 'settings'">
        <div class="row col-10">

          <ng-container *ngIf="!isCmdbEnabled()">
          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Name
            </div>
            <div class="col p-10">
              <input [(ngModel)]="values.Name" type="text" class="form-control" id="Name" placeholder=""
                     autocomplete="off">
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Send From E-mail
            </div>
            <div class="col p-10">

              <div *ngIf="values.SendFromEmail == null">
                <div class="input-group">
                  <input disabled [(ngModel)]="globalSettings.DefaultEmailAddress" type="email" class="form-control"
                         id="DefaultEmailAddress" placeholder="" autocomplete="off">
                  <div (click)="values.SendFromEmail = ''" class="btn btn-primary m-0">Override</div>
                </div>
              </div>

              <div *ngIf="values.SendFromEmail != null">
                <div class="input-group">
                  <input [(ngModel)]="values.SendFromEmail" type="email" class="form-control" id="SendFromEmail"
                         placeholder="" autocomplete="off">
                  <div (click)="values.SendFromEmail = null" class="btn btn-primary m-0">Revert</div>
                </div>
              </div>

            </div>
          </div>

          <div class="row"  *ngIf="values.SendFromEmail != null">
            <div class="col-md-3 formHeader alignRight p-10">
              Send From Name
            </div>
            <div class="col p-10">
              <div>
                <div class="input-group">
                  <input [(ngModel)]="values.SendFromName" type="email" class="form-control" id="SendFromName"
                         placeholder="" autocomplete="off">
                </div>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Re-open Status
            </div>
            <div class="col p-10">

              <ng-select [items]="options.status"
                         bindLabel="name"
                         bindValue="id"
                         [(ngModel)]="values.RequestDefaultStatusReopenedId"
                         [clearable]="true">
              </ng-select>

            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Requester Responded Status
            </div>
            <div class="col p-10">

              <ng-select [items]="options.status"
                         bindLabel="name"
                         bindValue="id"
                         [(ngModel)]="values.RequestDefaultStatusRespondedId"
                         [clearable]="true">
              </ng-select>

            </div>
          </div>

          <div class="row" *ngIf="values.ApplySla">
            <div class="col-md-3 formHeader alignRight p-10">
              Default SLA
            </div>
            <div class="col p-10">

              <ng-select [items]="options.slatemplates"
                         bindLabel="name"
                         bindValue="id"
                         [(ngModel)]="values.SlaTemplate"
                         [clearable]="true">
              </ng-select>

            </div>
          </div>

          <hr>
          </ng-container>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Security
            </div>
            <div class="col p-10">

              <ng-select [items]="groupPermissions"
                         bindLabel="name"
                         bindValue="id"
                         [(ngModel)]="values.Security_GroupPermissions"
                         [clearable]="false">
              </ng-select>

              <app-sdesk-multi-select *ngIf="values.Security_GroupPermissions == 2" [data]="options.group" [(selectedData)]="values.Security_GroupPermissions_Groups"></app-sdesk-multi-select>
              <div *ngIf="isDropdownEmpty" class="error-message" style="color: red;">
                Please select a group.
              </div>
            </div>
          </div>
          <ng-container *ngIf="!isCmdbEnabled()">
          <hr>



          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Resolution Codes
            </div>
            <div class="col-2 p-10">
              <input [(ngModel)]="values.ResolutionCodesEnabled" (ngModelChange)="toggle()" class="form-check-input"
                     id="ResolutionCodesEnabled" type="checkbox">
              <label for="ResolutionCodesEnabled" class="p-l-10">Enabled</label>
            </div>

            <div class="col-2 p-10">
              <input [(ngModel)]="values.ResolutionCodesRequired" class="form-check-input" id="ResolutionCodesRequired"
                     type="checkbox">
              <label for="ResolutionCodesRequired" class="p-l-10">Required</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Link to Customer
            </div>
            <div class="col-2 p-10">
              <input [(ngModel)]="values.CustomerEnabled" (ngModelChange)="toggle()" class="form-check-input"
                     id="CustomerEnabled" type="checkbox">
              <label for="CustomerEnabled" class="p-l-10">Enabled</label>
            </div>

            <div class="col-2 p-10">
              <input [(ngModel)]="values.CustomerRequired" class="form-check-input" id="CustomerRequired"
                     type="checkbox">
              <label for="CustomerRequired" class="p-l-10">Required</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Link to Service
            </div>
            <div class="col-2 p-10">
              <input [(ngModel)]="values.ServiceEnabled" (ngModelChange)="toggle()" class="form-check-input"
                     id="ServiceEnabled" type="checkbox">
              <label for="ServiceEnabled" class="p-l-10">Enabled</label>
            </div>

            <div class="col-2 p-10">
              <input [(ngModel)]="values.ServiceRequired" class="form-check-input" id="ServiceRequired" type="checkbox">
              <label for="ServiceRequired" class="p-l-10">Required</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Impact
            </div>
            <div class="col-2 p-10">
              <input [(ngModel)]="values.ImpactEnabled" (ngModelChange)="toggle()" class="form-check-input"
                     id="ImpactEnabled" type="checkbox">
              <label for="ImpactEnabled" class="p-l-10">Enabled</label>
            </div>

            <div class="col-2 p-10">
              <input [(ngModel)]="values.ImpactRequired" class="form-check-input" id="ImpactRequired" type="checkbox">
              <label for="ImpactRequired" class="p-l-10">Required</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Urgency
            </div>
            <div class="col-2 p-10">
              <input [(ngModel)]="values.UrgencyEnabled" (ngModelChange)="toggle()" class="form-check-input"
                     id="UrgencyEnabled" type="checkbox">
              <label for="UrgencyEnabled" class="p-l-10">Enabled</label>
            </div>

            <div class="col-2 p-10">
              <input [(ngModel)]="values.UrgencyRequired" class="form-check-input" id="UrgencyRequired" type="checkbox">
              <label for="UrgencyRequired" class="p-l-10">Required</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Priority
            </div>
            <div class="col-2 p-10">
              <input [(ngModel)]="values.PriorityEnabled" (ngModelChange)="toggle()" class="form-check-input"
                     id="PriorityEnabled" type="checkbox">
              <label for="PriorityEnabled" class="p-l-10">Enabled</label>
            </div>

            <div class="col-2 p-10">
              <input [(ngModel)]="values.PriorityRequired" class="form-check-input" id="PriorityRequired"
                     type="checkbox">
              <label for="PriorityRequired" class="p-l-10">Required</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Auto Priority
            </div>
            <div class="col p-10">
              <input (ngModelChange)="toggle()"
                     [disabled]="!values.PriorityEnabled || !values.ImpactEnabled || !values.UrgencyEnabled"
                     [(ngModel)]="values.AutoPriority" class="form-check-input" id="AutoPriority" type="checkbox">
              <label for="AutoPriority" class="p-l-10">Enabled</label>
              <i [matTooltip]="'Impact, Urgency and Priority must be enabled before you can enable Auto SLA'"
                 [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
                 class="far fa-question-circle m-l-5"></i>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Service Level Agreements
            </div>
            <div class="col p-10">
              <input (ngModelChange)="toggle()" [disabled]="!values.PriorityEnabled" [(ngModel)]="values.ApplySla"
                     class="form-check-input" id="ApplySla" type="checkbox">
              <label for="ApplySla" class="p-l-10">Enabled</label>
              <i [matTooltip]="'Priority must be enabled before you can enable Service Level Agreements'"
                 [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
                 class="far fa-question-circle m-l-5"></i>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Visible On Self Service Portal
            </div>
            <div class="col p-10">
              <input (ngModelChange)="toggle()" [(ngModel)]="values.VisibleOnSelfServicePortal" class="form-check-input"
                     id="VisibleOnSelfServicePortal" type="checkbox">
              <label for="VisibleOnSelfServicePortal" class="p-l-10">Enabled</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Canned Responses
            </div>
            <div class="col p-10">
              <input (ngModelChange)="toggle()" [(ngModel)]="values.CannedResponseEnabled" class="form-check-input"
                     id="CannedResponseEnabled" type="checkbox">
              <label for="CannedResponseEnabled" class="p-l-10">Enabled</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Satisfaction Survey
            </div>
            <div class="col p-10">
              <input (ngModelChange)="toggle()" [(ngModel)]="values.SurveyEnabled" class="form-check-input"
                     id="SurveyEnabled" type="checkbox">
              <label for="SurveyEnabled" class="p-l-10">Enabled</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Change Management
            </div>
            <div class="col p-10">
              <input (ngModelChange)="toggle()" [(ngModel)]="values.ChangeManagementEnabled" class="form-check-input"
                     id="ChangeManagementEnabled" type="checkbox">
              <label for="ChangeManagementEnabled" class="p-l-10">Enabled</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 formHeader alignRight p-10">
              Major Incident Module
            </div>
            <div class="col p-10">
              <input (ngModelChange)="toggle()" disabled [(ngModel)]="values.MIModuleEnabled" class="form-check-input"
                     id="MIModuleEnabled" type="checkbox">
              <label for="MIModuleEnabled" class="p-l-10">Enabled</label>
              <i [matTooltip]="'Please contact support@sdesk.co.uk to enable the major incident module'"
                 [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
                 class="far fa-question-circle m-l-5"></i>
            </div>
          </div>
        </ng-container>
          <div class="row">
            <div class="col-md-3">
            </div>
            <div class="col p-10">
              <div (click)="saveData()" class="btn btn-primary">Save</div>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="selectedTab == 'customfields'">
        <app-admin-custom-fields [requestTypeId]="this.typeId"></app-admin-custom-fields>

      </div>

      <div *ngIf="selectedTab == 'status'">
        <app-admin-status [requestTypeId]="this.typeId"></app-admin-status>
      </div>

      <div *ngIf="selectedTab == 'assetTypes'">
        <app-admin-asset-types [requestTypeId]="this.typeId"></app-admin-asset-types>
      </div>

      <div *ngIf="selectedTab == 'impact'">
        <app-admin-impact [requestTypeId]="this.typeId"></app-admin-impact>
      </div>

      <div *ngIf="selectedTab == 'urgency'">
        <app-admin-urgency [requestTypeId]="this.typeId"></app-admin-urgency>
      </div>

      <div *ngIf="selectedTab == 'priority'">
        <app-admin-priority [requestTypeId]="this.typeId"></app-admin-priority>
      </div>

      <div *ngIf="selectedTab == 'autopriority'">
        <app-admin-auto-priority [requestTypeId]="this.typeId"></app-admin-auto-priority>
      </div>

      <div *ngIf="selectedTab == 'resolutioncodes'">
        <app-admin-resolution-codes [requestTypeId]="this.typeId"></app-admin-resolution-codes>
      </div>

      <div *ngIf="selectedTab == 'cabs'">
        <app-admin-cab [requestTypeId]="this.typeId"></app-admin-cab>
      </div>

      <div *ngIf="selectedTab == 'mitasks'">
        <app-admin-major-incident-tasks [requestTypeId]="this.typeId"></app-admin-major-incident-tasks>
      </div>

      <div *ngIf="selectedTab == 'soc-feeds'">
        <app-admin-soc-direct-feeds [requestTypeId]="this.typeId"></app-admin-soc-direct-feeds>
      </div>

      <div *ngIf="selectedTab == 'soc-email-feeds'">
        <app-admin-email-parse [filterRequestTypeId]="this.typeId"></app-admin-email-parse>
      </div>

      <div *ngIf="selectedTab == 'soc-webhook-feeds'">
        <app-admin-webhooks [filterRequestTypeId]="this.typeId"></app-admin-webhooks>
      </div>

      <div *ngIf="selectedTab == 'soc-scheduled-tasks'">
        <app-admin-scheduled-requests [filterRequestTypeId]="this.typeId"></app-admin-scheduled-requests>
      </div>

      <div *ngIf="selectedTab == 'custom-survey'">
        <app-admin-custom-survey [requestTypeId]="this.typeId"></app-admin-custom-survey>                                                                                                                     
      </div> 

    </div>
  </div>
</div>







