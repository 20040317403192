
import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef,Injectable} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {AdminCustomSurveyService} from "../../../../../../services/api/admin/admin-custom-survey/admin-custom-survey.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-admin-custom-survey-create-edit-dialog',
  templateUrl: './admin-custom-survey-create-edit-dialog.component.html'
})
@Injectable({
  providedIn: 'root'
})
export class AdminCustomSurveyCreateEditDialogComponent implements Dialog {

  @Input() data; // {id: 122, requestTypeId: 12}
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  isSaving = false;

  loadedId = null;
  requestTypeId = null;
  isRateInputVisible: boolean = false;

  loadedData;

  surveyFieldTypeOptions = [
    { id: 1,  name: 'Single Textbox',description: 'A single-line text input field'},
    { id: 2,  name: 'Single Choice (Rating)',description: 'A single-choice field for rating options'},
    { id: 3,  name: 'Boolean (True/False)',description: 'A field for true/false or yes/no answers'},
  ];
  

  constructor(
    private apiAdminCustomSurveyService:AdminCustomSurveyService,
    private toastr: ToastrService
  ) {}


  ngOnInit(): void {
    this.requestTypeId = this.data.requestTypeId;

    if(this.data.id !== null){
      this.loadedId = this.data.id;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        RequestTypeId: this.requestTypeId
      }

      this.isLoading = false;

    }
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCustomSurveyService.get(this.loadedId).then(response => {
      this.loadedData = response;
      console.log(this.loadedData);
      this.isLoading = false;
      if(this.loadedData.RateRange && this.loadedData.FieldType==2){
        this.isRateInputVisible=true;
      }
      
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){

    this.isSaving=true;
    let boolValue: boolean = this.loadedData.IsRequired;
    let numericValue: number = boolValue ? 1 : 0;
    this.loadedData.IsRequired=numericValue;

    if (this.loadedData.RateRange<0) {
      this.toastr.error("Invalid survey reminder format. Please provide a valid format.");
      return;
  }

    if(this.loadedId){
      if (this.loadedData.Shared){
        this.loadedData.RequestTypeId=null
      }else{
        this.loadedData.RequestTypeId=this.requestTypeId
      }
      this.apiAdminCustomSurveyService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminCustomSurveyService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }

  onFieldTypeChange(selectedValue: any): void {
    if (selectedValue === 2) {
      // Show the "Amount of rate" input
      this.isRateInputVisible = true;
      this.loadedData.RateRange = null;
    }
     else {
      this.isRateInputVisible = false;
      this.loadedData.RateRange = 0;
    }
  }
  
}
