
<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Custom Survey</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Question to ask <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.FieldName" type="text" class="form-control" id="Name" placeholder="Enter Your Question" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Type <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <ng-select [items]="surveyFieldTypeOptions"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="loadedData.FieldType"
                  (change)="onFieldTypeChange(loadedData.FieldType)"
                  [disabled]="this.loadedData.Id">
            <!-- Template for displaying options with description on hover -->
            <ng-template ng-option-tmp let-item="item">
                <div [title]="item.description">{{ item.name }}</div>
            </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row" *ngIf="isRateInputVisible==true || loadedData.RateRange">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Rate range<span class="text-danger">*</span>
        <span>
          <i [matTooltip]="'A rate range is a scale used to show different levels of ratings.For example, setting it to 5 means the rating will be 1 to 5.'"
          [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
          class="far fa-question-circle m-l-5"></i>
        </span>
      </div>
      <div class="col-sm p-10">
        <input type="number" class="form-control" [(ngModel)]="loadedData.RateRange " placeholder="Enter amount" 
               min="1"/>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Required
      </div>
      <div class="col-sm p-10">
        <input type="checkbox" class="form-check-input" id="FieldRequired" name="FieldRequired" [(ngModel)]="loadedData.IsRequired" >
      </div>
    </div>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary" [disabled] ="isSaving">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>