<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-container *ngIf="!isLoading">
<div  class="table-responsive p-l-20 p-r-20">
  <!-- button for identify the client -->
<div
  (click)="!icxClient?.ClientName && create()"
  class="btn float-end"
  [ngClass]="icxClient?.ClientName ? 'btn-success' : 'btn-primary'"
  [class.disabled]="!!icxClient?.ClientName"
  [attr.disabled]="!!icxClient?.ClientName"
  [matTooltip]="icxClient?.ClientName ? icxClient.ClientName + ' is connected to ICX' : 'Need to enter a client name to connect to ICX'"
  [matTooltipPosition]="'above'"
  [matTooltipClass]="'sdesk-tooltip'"
>
  {{ icxClient?.ClientName ? icxClient.ClientName : 'Connect To ICX' }}
</div>

<!-- button for identify the delete -->
<div class="btn" 
     (click)="icxClient.ClientName && delete(icxClient.ClientName)" 
     [ngClass]="icxClient?.ClientName ?'btn btn-danger':''">
  {{ icxClient?.ClientName ? 'Delete the client' : '' }}
</div>

  <table class="table align-middle tableStyle">
    <thead>
    <tr>
      <th>App Name</th>
      <th>Status</th>
      <th style="width: 100px;"></th>

    </tr>
    </thead>
    <tbody>
    <tr>
      <td>
          Jira by Atlassian
      </td>
      <td>
        <span>
          <i class="fas" [ngClass]="{'fa-check-circle green-icon': jiraConnected, 'fa-times-circle red-icon': !jiraConnected}"></i>
          {{ jiraConnected ? 'Connected' : 'Disconnected' }}
        </span>
      </td>
      <td>
        <i class="fas fa-cog float-end m-r-20 divLink" (click)="createJiraConfig()"></i></td>
    </tr>
    </tbody>
  </table>
</div>
</ng-container>

