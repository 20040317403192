<app-modal [modalSize]="'modal-large'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Audit Log</h5>
    <i (click)="this.closeModal()" class="fas fa-times fa-2x float-end divLink"></i>

  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table align-middle tableStyle">
        <thead>
        <tr>
          <th class="">Date</th>
          <th class="">User</th>
          <th class="">Type</th>
          <th class="col-sm-5">Info</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let event of this.auditEvents">
          <td>
            <app-friendly-time [utcTimeData]="event.AuditDateTime" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time>
          </td>
          <td>{{event.SystemTriggered ? 'System' : event.UserName}}</td>
          <td>{{event.AuditType}}</td>
          <td>{{event.EventInfo}}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
  <div class="modal-footer">
    <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Close</div>
  </div>
</app-modal>
