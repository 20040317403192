
import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminIcxService {

  apiUri = '/api/admin/icx'

  constructor(private apiService: ApiService) {
  }

  create(data) {
    return this.apiService.post(this.apiUri, data);
  }

  delete(data) {
    return this.apiService.delete(`${this.apiUri}?client_name=${data}`);
  }

  get(){
    return this.apiService.get(this.apiUri);
  }

  createJiraConfig(data){
    return this.apiService.post(this.apiUri + '/jira-config', data);
  }

  getJiraConfig(){
    return this.apiService.get(this.apiUri + '/jira-config');
  }

  getJiraConfigGroup(){
    return this.apiService.get(this.apiUri + '/jira-config/group');
  }

  createJiraConfigGroup(data){
    return this.apiService.post(this.apiUri + '/jira-config/group', data);
  }

  updateJiraConfigGroup(id, data){
    return this.apiService.put(this.apiUri + '/jira-config/group/'+id, data);
  }

  activeDisableJiraConfigGroup(id,data){
    return this.apiService.patch(this.apiUri + '/jira-config/group/'+id, data);
  }


}
